import { createApp } from 'vue';
import App from './App.vue';
import VueLazyLoad from 'vue3-lazyload';

import './assets/css/main.css';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


const app = createApp(App);
app.use(VueLazyLoad);

/* app.directive('img-load', {
  
    beforeMount: async (el, binding) => {
        console.log("mounted")
        console.log(binding.value)
        await lazyLoadingImage(el, binding.value)
    }
}) */
app.mount('#app');
