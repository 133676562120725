<template>
<div>
    <loader v-if="loading" :color="'#f5cd79'" :borderWidth="10" :duration="1" :size="70" :background="'#000'" />
    <div class="logo-wrpr">
        <a href="https://tegelmedia.net" target="_blank">
        <img id="media_1" class="logo" src="@/assets/images/tegelmedia_1.png">
        </a>
        <span class="btn init-mute" @click="togglePlay" v-show="soundLoaded">
            <div v-if="play">
                <i class="far fa-pause-circle"></i>
            </div>
            <div v-else>
                <i class="far fa-play-circle"></i>  
            </div>
        </span>
    </div>
    <img id="media_2" class="logo" src="@/assets/images/tegelmedia_2.png">
    <div id="img-wrpr"></div>
    <transition-group name="list" tag="div">
    <img class="img-part list-item" v-for="(image, ind) in images"  :key="ind" :src="`https://www.spof.at${image.img_url}`" @load="onLoaded(image.ts)" v-show="image.loaded"/>
    </transition-group>
    <transition name="bottom" tag="div">
    <loader v-if="isLoading" class="bottom-loader" :color="'#f5cd79'" :borderWidth="5" :duration="1" :size="26" :background="'#000'" />
    </transition>
</div>
</template>

<script>

import {ref, reactive, computed, onMounted, onUnmounted, onBeforeMount, registerRuntimeCompiler} from 'vue';
import loader from '@nulldreams/vue-loading/src/components/loader'
import {Howl, Howler} from 'howler';

import * as Tone from 'tone';
import  getScroller  from 'scroll-speed';

export default {
    name: 'Area',
    components: {
        loader,
    },
    setup(){
        
        const player = ref();
        const scrollSpeed = ref();
        let isScrolling = null;
        const part = ref(0);
        const images = ref([]);
        const limit = ref(3);
       
        const loading = ref(true);
        const play = ref(false)
        const audioSource = ref();
        const isLoading = ref(false);
        const loadedImages = ref([]);
        const soundLoaded = ref(false);
        const currentLoading = computed(()=>{
            let obj = images.value.map(el=>{
                return el.loaded
            })
            let anyLoading = obj.every(v => v===true)
            
            return !anyLoading && !loading.value
        });
        let initLoading = true;
        let round = 0;
        let countImage = 0;

        onMounted(()=>{
            scrollSpeed.value = getScroller();
            
            window.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onResize);

            audioSource.value = new Howl({
            src: [ require('../assets/mp3/tegelmedia_sound.mp3') ],
                loop: true,
                volume: 0.5,
                onload: ()=>{
                    soundLoaded.value = true;
                }
            });

        })
        
        const onLoaded = (timestamp)=>{
            images.value.forEach((el, ind)=>{
                if(el.ts==timestamp){
                    el.loaded = true
                }
            })
        }
        function togglePlay(e){
            if(play.value){
                audioSource.value.pause();
            }else{
                audioSource.value.play();
            }

            play.value = !play.value;
            
        }

        onBeforeMount(async ()=>{
            onResize()
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const targetHeight = Math.trunc( (9/16)*windowWidth);
            const setupCount = Math.ceil(windowHeight/targetHeight)+1

            
            await fetchImage()
         
           
        })

        // Handle on resize
        const onResize = ()=>{
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const targetHeight = Math.trunc( (9/16)*windowWidth);
            if ((Math.ceil(windowHeight/targetHeight)+1) > limit.value){
                limit.value = Math.ceil(windowHeight/targetHeight)+2;
            }
        }
        onUnmounted(()=>{
            window.removeEventListener('scroll', onScroll);
        })

        const loadImage = async (from, to,data) => {
            let img = document.createElement('img')
           /*  img.id = `${round}-${id}` */
           if(from==to) return;
            let imgHelper = new Image();
            imgHelper.src =`${data[from].img_url}`
            imgHelper.id = `img-${countImage}`;
            imgHelper.style.opacity = "0";
            imgHelper.style.display = "block";
            imgHelper.style.width = "100%";
            imgHelper.style.zIndex = "1";
            
            imgHelper.onload = (countImage)=>{
                countImage++;
                imgHelper.style.transition = "opacity 2s";
                imgHelper.style.opacity  = "1";
                const next = from+1;
                 document.getElementById('img-wrpr').appendChild(imgHelper)
                loadImage(next,to,data);
                
            }
           
        }
        const handleImage = async (data)=>{
            await loadImage(0, data.length,data)
        }

        // Fetch images
        const fetchImage = async () =>{
            
            
            try {
                
                const response = await fetch(`https://www.spof.at/api/images?part=${part.value}&limit=${limit.value}`);
                const parsedResponse = await response.json();

                if (parsedResponse.data){
                    
                    let result = parsedResponse.data.map((el)=>{
                        let obj = Object.assign({}, el);
                        obj.loaded = false;
                        return obj;
                    })
                    images.value = await [...images.value, ...result]
                    part.value = part.value + limit.value;
                }else{
                    part.value = 0;
                    round++;
                     console.log("error ok")
                }

                if (initLoading){
                    loading.value = false;
                    initLoading = false;
                }
            } catch(err){
                part.value = 0;
                console.log("error")
            }

            
        }


        // Handle scroll event
        const onScroll = async () =>{
            if ((window.innerHeight + window.scrollY) >= 0.95*document.body.offsetHeight) {

                if(isLoading.value){
                    return;
                }
                isLoading.value = true;
                await fetchImage()
                isLoading.value = false;
            }
            const speed = scrollSpeed.value[1];
            const sign = Math.sign(speed);
            let delta = speed == 0 ? 1 : Math.trunc(Math.log(Math.abs(speed)) / Math.log(5))
        
            delta = sign == -1 ? 0.7 : 1.3;

            audioSource.value.rate(delta);
            clearTimeout( isScrolling );
           
            isScrolling = setTimeout(() => {
                scrollSpeed.value.flush();
                audioSource.value.rate(1);
            }, 100);

        }

        return {
            loading,
            player,
            scrollSpeed,
            images,
            play,
            togglePlay,
            audioSource,
            isLoading,
            limit,
            onLoaded,
            soundLoaded,
            loadedImages,
            currentLoading
        }
    }
}
</script>

<style scoped>
img {
    width: 100%;
    padding: 0;
    display: block;

}
p{
    margin-block-start:  none !important;
}
.list-item {
  display: block;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.8s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.bottom-item {
  display: block;
}
.bottom-enter-active,
.bottom-leave-active {
  transition: all 1.2s ease;
}
.bottom-enter-from,
.bottom-leave-to {
  opacity: 0;
}

.bottom-loader{
    position: fixed !important;
    min-height: 10px;
    height: 50px !important;
    top: unset;
    bottom: 0;
    background-color: rgba(3, 3, 3, 0.4) !important;
}

.logo-wrpr{
    position: fixed;
    width: 127px;
    padding: 20px;
    z-index: 32;
}

.init-mute {
    -moz-animation: colorchange 0.8s infinite alternate;
    -o-animation: colorchange 0.8s infinite alternate;
    -ms-animation: colorchange 0.8s infinite alternate;
    animation: colorchange 0.8s infinite alternate;
    animation: colorchange 0.8s infinite alternate;
    -webkit-animation: colorchange 0.8s infinite alternate;
}


@keyframes colorchange {
    0% { color: rgba(13,13,13,1); }
    20% { color: rgba(13,13,13,0.9); }
    40% { color: rgba(13,13,13,0.8); }
    60% { color: rgba(13,13,13,0.7); }
    80% { color: rgba(13,13,13,0.6); }
    100% { color: rgba(13,13,13,0.4);}
}

@-webkit-keyframes colorchange {
    0% { color: rgba(13,13,13,1); }
    20% { color: rgba(13,13,13,0.9); }
    40% { color: rgba(13,13,13,0.8); }
    60% { color: rgba(13,13,13,0.7); }
    80% { color: rgba(13,13,13,0.6); }
    100% { color: rgba(13,13,13,0.4);}
}
@-moz-keyframes colorchange {
    0% { color: rgba(13,13,13,1); }
    20% { color: rgba(13,13,13,0.9); }
    40% { color: rgba(13,13,13,0.8); }
    60% { color: rgba(13,13,13,0.7); }
    80% { color: rgba(13,13,13,0.6); }
    100% { color: rgba(13,13,13,0.4);}
}
@-ms-keyframes colorchange {
    0% { color: rgba(13,13,13,1); }
    20% { color: rgba(13,13,13,0.9); }
    40% { color: rgba(13,13,13,0.8); }
    60% { color: rgba(13,13,13,0.7); }
    80% { color: rgba(13,13,13,0.6); }
    100% { color: rgba(13,13,13,0.4);}
}
@-o-keyframes colorchange {
    0% { color: rgba(13,13,13,1); }
    20% { color: rgba(13,13,13,0.9); }
    40% { color: rgba(13,13,13,0.8); }
    60% { color: rgba(13,13,13,0.7); }
    80% { color: rgba(13,13,13,0.6); }
    100% { color: rgba(13,13,13,0.4);}
}


.fade-in-enter-active {
  transition: all 0.5s ease;
}

.fade-in-leave-active {
  transition: all 0.5s ease;
}

.fade-in-enter, .fade-in-leave-to {
  position: absolute; /* add for smooth transition between elements */
  opacity: 0;
}

.logo-wrpr span{
    font-size: 2.2em;
    cursor: pointer;
    position: absolute;
    margin-top: 0.4em;
}

.logo-wrpr a:hover{
    opacity: 1;
    animation: step-start;
    animation-play-state: paused;
}
#media_2 {
    position: fixed;
    bottom: 0px;
    width: 120px;
    right: 0px;
    z-index: 555;
}

.bottom-loader{
    
}

@media screen and (max-width: 768px) {
  #media_2 {
    width: 80px;
  }
}

</style>
