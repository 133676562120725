<template>
<div id="app">
  <Area />
</div>
</template>

<script>
import Area from './components/Area.vue'

export default {
  name: 'App',
  components: {
    Area
  }
}
</script>

<style scoped>

</style>
